import React from 'react'
import Layout from '../components/layout'
import About from '../components/About'
import SEO from "../components/seo"
export default () => {
    const description = "Welcome to HolsumPR! At Holsum, we are always at your service. Providing the highest quality baked products since 1958."
    return (
        <Layout>
            <SEO description={description} title="About" keywords={[`bread`, `application`, `react`, `bakery`, `puerto rico`]} />
            <About />
        </Layout>

    )

}