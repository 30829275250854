import React, { useEffect, useState } from 'react'
// import { Parallax, Background } from 'react-parallax';
import DropletsBackground from '../../images/droplets-01.png'
import { TweenMax } from 'gsap';
import { useTranslation } from "react-i18next";
import PropTypes from 'prop-types'
import style from './style.module.scss'

const PageHeader = ({ height, header, headercenter, headerleft, headerRight, smallheader, subheader, image, color, fontFamily, fontSize }) => {

    const { t } = useTranslation();
    const animateHeader = () => {
        TweenMax.to('#middle', 0.8, { opacity: 1.0 })
        TweenMax.from('#middle', 0.8, { x: window.innerWidth })
        TweenMax.to('#sub', 0.8, { opacity: 1.00, delay: 0.8 })
        TweenMax.from('#sub', 0.8, { y: 200, delay: 0.8 })
        TweenMax.to('#left', 0.8, { opacity: 1.0, delay: 0.8 })
        TweenMax.from('#left', 0.8, { x: -window.innerWidth, delay: 0.2 })
        TweenMax.to('#leftleft', 0.8, { opacity: 1.0 })
        TweenMax.from('#leftleft', 0.8, { x: -window.innerWidth })
    }

    useEffect(() => {
        animateHeader()
    }, [])

    return (
        <div className={style.pageHeaderWrapper} style={{ height: height ? `${height}vh` : null, backgroundImage: `url(${DropletsBackground})`, backgroundSize: 'contain', color: color ? `${color}` : null, fontFamily: fontFamily ? `${fontFamily}` : null, fontSize: fontSize ? `${fontSize}rem` : null }}>
            {image ?
                <div className={style.parallaxContainer}>
                    <div className={style.parallaxContent}>
                        {header ? <h2 id="left" className={`${style.headerBottom} ${style.headerLabel}`} style={{ color: color ? color : '' }}>{header}</h2> : null}
                    </div>
                </div> : null}
            {headercenter ? <h2 id="middle" className={`${style.headerMiddle} ${style.headerLabel}`}>{t(headercenter)}</h2> : null}
            {headerleft ? <div id="leftleft" className={style.headerLeftWrapper}>
                <h2 style={{ color: color ? color : null }} className={style.headerLeft}>{headerleft}</h2>
            </div> : null}
            {headerRight ? <div className={style.headerRightWrapper}>
                <h2 className={style.headerRight}>{headerRight}</h2>
            </div> : null}
            {subheader ? <h3 id="sub" className={`${style.subHeader} ${style.headerLabel}`}>{t(subheader)}</h3> : null}
            {smallheader ? <h4 className={style.smallHeader}>{smallheader}</h4> : null}
        </div>

    )
}

PageHeader.propTypes = {
    height: PropTypes.number,
    header: PropTypes.string,
    headercenter: PropTypes.string,
    headerleft: PropTypes.string,
    subheader: PropTypes.string,
    smallheader: PropTypes.string,
    background: PropTypes.string,
    color: PropTypes.string,
    fontFamily: PropTypes.string,
    fontSize: PropTypes.number,
    image: PropTypes.bool
}

PageHeader.defaultProps = {
    height: null,
    header: null,
    headercenter: null,
    headerleft: null,
    headerRight: null,
    subheader: null,
    smallheader: null,
    background: null,
    color: null,
    fontFamily: null,
    fontSize: null,
    image: false
}

export default PageHeader