import React, { useEffect, useState } from 'react'
import style from './style.module.scss'
import PageHeader from '../PageHeader'
import { useTranslation } from "react-i18next";
import { TranslationConstants } from '../../Constants'
import Copy from '../Copy'
import Scrollable from '../Scrollable'
import Footer from '../Footer'
import BackgroundImage from '../../images/map_pr_large.png'
import Zoom from 'react-reveal/Zoom';

const About = () => {
    const { t } = useTranslation();
    return (
        <div>
            <Scrollable>
                <div className={style.headerWrapper}>
                    <img id="background_img" className={style.backgroundImage} src={BackgroundImage} />
                    {/* <div className={style.headerContentWrapper}>
                        <h1>{t(TranslationConstants.aboutUs)}</h1>
                    </div> */}
                </div>
                <div className={style.content}>
                    <Zoom>
                        <Copy contentLeft={t(TranslationConstants.aboutPageLeftContent)}
                            contentRight={t(TranslationConstants.aboutPageRightContent)} />
                    </Zoom>
                </div>
                <Footer />
            </Scrollable>
        </div>
    )

}

export default About